import {
  PaymentElement,
  PaymentRequestButtonElement
} from '@stripe/react-stripe-js'
import {useState, useEffect} from 'react'
import {useStripe, useElements} from '@stripe/react-stripe-js';
import { updatePaymentIntent, savePayload } from '../../../actions/basket-actions'
//import BingTracking from 'react-tracking-bing';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import base64 from 'base-64' 
import { Base64 as base_64 } from 'js-base64';
import { useDispatch } from "react-redux";
import { Button } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [errorMessage, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 
  const [isDisabled, setIsDisabled] = useState(true);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const [clickLoader, setClickLoader] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  let disabled = false;
  let buttonText = 'Submit Payment';
  let success = 'Thank you. Your order has been placed.';

  if (isLoading) {
    buttonText = 'Please wait...'
    disabled = true; 
  } else {
    disabled = false;
  } 

  let terms;
  let privacy;
  if (global.config.websiteId == 1) {
    terms = 'https://www.forthwithlife.co.uk/terms-of-service/';
    privacy = 'https://www.forthwithlife.co.uk/privacy-policy/';
  } else {
    terms = 'https://www.sportsbloodtests.co.uk/terms-of-service/';
    privacy = 'https://www.sportsbloodtests.co.uk/privacy-policy/';
  }

  useEffect(() => { 
    if (stripe) { 
      if (props.requestType == 'buySubscription' || props.requestType == 'buySingleProduct') {
 
        let totalAmount =  parseFloat((props.totalAmount * 100).toFixed(2));
        // For full documentation of the available paymentRequest options, see:
        // https://stripe.com/docs/stripe.js#the-payment-request-object
        const paymentRequestStripe = stripe.paymentRequest({
          country: 'GB',
          currency: 'gbp',
          total: {
            label: 'Forth',
            amount: totalAmount,
          },
        });

        paymentRequestStripe.on('paymentmethod', function(event) { 
          handlePayment(event); 
        });

        paymentRequestStripe.on('token', ({complete, token, ...data}) => {
          console.log('Received Stripe token: ', token);
          console.log('Received customer information: ', data);
        });

        paymentRequestStripe.on('cancel', function() { 
          setClickLoader(false);
        });
        
        paymentRequestStripe.canMakePayment().then((result) => {
          if (result && !result.applePay && !result.googlePay) {
            setCanMakePayment(false);
          } else {
            setCanMakePayment(!!result); 
          }
        }, (err) => {
          console.log(err);
        }); 

        setCanMakePayment(false);
        setPaymentRequest(paymentRequestStripe);
   
      } 
    }
  }, [stripe, props]);
 
  

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    if (props.agreeTerms) {

      
      var page = this;
      setIsLoading(true); 
      if (props.requestType == 'buySubscription') {
        buySubscription(0, "");
      } else {
        buySingleTest(0, "");
      }  
    } else {
      props.setTermsError(true)
      setTimeout(()=> {
        document.querySelector(".alert.alert-danger.basketAlert")?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      },200)
    }
  }
  

  const handlePayment = (e) => {
    if (props.requestType == 'buySubscription') {
      this.buySubscription(1, event);
    } else if (props.requestType == 'buySingleProduct') {
      this.buySingleTest(1, event);
    }
  }
  
 

  const buySingleTest = async (isAppleGooglePay, event) => {
    var page = this;
    var doNotReview = props.doNotReview;
    if (doNotReview) {
      doNotReview = 1;
    } else {
      doNotReview = 0;
    }
    setIsLoading(true);  

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
 
    let payload = { 
      userId: props.userId ? props.userId : localStorage.getItem('customerId'),
      userIp: props.userIp,
      websiteId: props.websiteId,
      amount: props.amount,
      id: props.paymentIntent,
      orderReference: props.orderReference,
      orderId: props.orderId,
      stripeCustomerId: props.stripeCustomerId, 
      userEmail: props.userEmail ? props.userEmail : props.customerEmailAddress ,
      totalAmount: props.totalAmount,
      totalSaving: props.totalSaving,
      subscriptionOrder: props.subscriptionOrder,
      activationKit: props.activationKit,
      partnerId: props.partnerId,
      partnerCode: props.partnerCode,
      overidePayPalProcess: props.overidePayPalProcess,
      voucherId: props.voucher.iD,
      f: props.f,
      freeOrderEncryptedUrl: props.freeOrderEncryptedUrl,
      stripeToken: props.paymentIntent,
      orderCookieId: props.orderCookieId,
      doNotReview: doNotReview,
      credit: props.credit,
      discountedCredit: props.discountedCredit,
      receiptEmailAddress: global.config.websiteId == 3 ? props.receiptEmailAddress : "",
      grantPermissionToPartner: 1,
      cart: props.cart,
      partner: props.partner,
      phlebotomyOption: props.phlebotomyOption,
      basketTestItems: props.basketTestItems,
      voucher: props.voucher,
      customerEmailAddress: props.customerEmailAddress,
      customerFirstName: props.customerFirstName,
      customerLastName : props.customerLastName,
      checkoutFromPartner: props.checkoutFromPartner,
      clientEmailsCheck: props.clientEmailsCheck,
      clientReciept: props.clientReceipt,
      clientReferenceId: window?.Rewardful?.referral ?? ''
    } 
    let encryptedPayload = base_64.encode(JSON.stringify(payload));
    localStorage.setItem("klarnaPayload", encryptedPayload);
    
    let payloadWindow='payload'

    let payloadReference = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    
    // Loop to generate characters for the specified length
    for (let i = 0; i < 5; i++) {
        const randomInd = Math.floor(Math.random() * characters.length);
        payloadReference += props.orderId + '-' + characters.charAt(randomInd);
    }

 
    if (isAppleGooglePay == 0) {  
      setIsLoading(true); 

      await dispatch(savePayload(payloadReference,encryptedPayload))
      .then((response) => {
        if (response.error) {
          props.cardFormState('failure')
          setIsLoading(false);
          if (event) {
            event.complete('fail');
          }
        } 
      });

      
      const error = await stripe.confirmPayment({
        elements,
        confirmParams: { 
          return_url: `${window.location.origin}/checkout-success/`+payloadReference,
        },  
        redirect: 'if_required',
      }).then(function(result) { 
        if (result.error) {
          setIsLoading(false);
          setMessage(result.error.message); 
        } else { 
          completePurchase(payload, '');
        } 
      }); 
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occured.");
        }
      }  
    } else { 
      this.props.stripe.confirmCardPayment(
      props.clientSecret, {payment_method: event.paymentMethod.id}).then(function(result) {
        if (result.error) {
          setMessage(result.error.message);
          setIsLoading(false);  
        } else { 
          page.setState({loadingPayment: true});
          page.completePurchase(payload, event); 
        }
      }); 
    }  
  }

  const buySubscription = async (isAppleGooglePay, event) => {
    var doNotReview = props.doNotReview;
    if (doNotReview) {
      doNotReview = 1;
    } else {
      doNotReview = 0;
    }
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
   
    let payload = {
      userId: props.userId,
      userIp: '',
      websiteId: props.websiteId,
      amount: props.amount,
      id: props.paymentIntent,
      orderReference: props.orderReference,
      orderId: props.orderId,
      stripeCustomerId: props.stripeCustomerId, 
      userEmail: props.userEmail,
      totalAmount: props.totalAmount,
      totalSaving: props.totalSaving,
      subscriptionOrder: props.subscriptionOrder,
      activationKit: props.activationKit,
      partnerId: props.partnerId,
      partnerCode: props.partnerCode,
      overidePayPalProcess: props.overidePayPalProcess,
      voucherId: props.voucher.iD,
      f: props.f,
      freeOrderEncryptedUrl: props.freeOrderEncryptedUrl,
      stripeToken: props.paymentIntent,
      orderCookieId: props.orderCookieId,
      doNotReview: doNotReview,
      credit: props.credit,
      discountedCredit: props.discountedCredit,
      receiptEmailAddress: global.config.websiteId == 3 ? props.receiptEmailAddress : "",
      grantPermissionToPartner: 1,
      checkoutFromPartner: props.checkoutFromPartner,
      clientEmailsCheck: props.clientEmailsCheck,
      clientReciept: props.clientReceipt,
      clientReferenceId: window?.Rewardful?.referral ?? ''
    } 
    
    let payloadWindow = base64.encode(payload);
 
    if(isAppleGooglePay == 0) {

      setIsLoading(true);

      const error = await stripe.confirmSetup({
        elements,
        confirmParams: {
          // Return URL where the customer should be redirected after the SetupIntent is confirmed.
          return_url: `${window.location.origin}/completion/`+payloadWindow,
        },
        redirect: 'if_required',
      }).then(function(result) {
 
        if (result.error) {
          setIsLoading(false);
          setMessage(result.error.message);

        } else { 
          completePurchase(payload, '');
        }

      });
 
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occured.");
        }
      }
 
    } else {
      stripe.confirmCardSetup(
      props.clientSecret, {payment_method: event.paymentMethod.id}).then(function(result) {
        if (result.error) {
          setIsLoading(false);
          setMessage(result.error.message);
        } else { 
          setIsLoading(true); 
          completePurchase(payload, event); 
        }
      });
    } 
  }

  const runGA = async (ordersUpdated) => { 
    //BingTracking.initialize('49000593');

    
    var GaCode = config.GAEDGECODE;
    var sku = 'FE'

    if (window.location.hostname == 'app.forthwithlife.co.uk' || window.location.hostname == 'shop.forthwithlife.co.uk' || window.location.hostname == 'shoptemp.forthwithlife.co.uk') {
      GaCode = config.GALIFECODE;
      sku = 'F';
    } else if (window.location.hostname == 'app.forthedge.co.uk' || window.location.hostname == 'shop.forthedge.co.uk' || window.location.hostname == 'app.sportsbloodtests.co.uk' || window.location.hostname == 'shop.sportsbloodtests.co.uk') {
      GaCode = config.GAEDGECODE;
      sku = 'FE';
    } 

    ReactGA.initialize(GaCode);
    ReactGA.plugin.require('ecommerce');

    var doubleKit = 0;
 
    ordersUpdated.forEach(function (item, index) {

      if (doubleKit == 0) {
   

        if (global.config.websiteId == 2) {
          ReactGA.plugin.execute('create', GaCode ,'auto');

          if (item.voucherCode != '') {

            ReactGA.plugin.execute('ecommerce', 'addTransaction', {
              'id': item.orderReference,                     // Transaction ID. Required.
              'revenue': item.orderPaid,
              'coupon': item.voucherCode
            });


          } else {

            ReactGA.plugin.execute('ecommerce', 'addTransaction', {
              'id': item.orderReference,                     // Transaction ID. Required.
              'revenue': item.orderPaid
            });

          }
          

          ReactGA.plugin.execute('ecommerce', 'addItem', {
            'id': item.orderReference,
            'name': item.testName,
            'sku':  sku+item.testId,
            'price': item.testPrice,
            'brand': item.testType,
            'category': item.testGroup,
            'quantity': 1
          });
          


          ReactGA.plugin.execute('ecommerce', 'send'); 

          ReactPixel.track('Purchase', {value: item.orderPaid, currency: 'GBP'});
        }
      
        if (item.testId == 100815 || item.testId == '100815' || item.testId == 237545 || item.testId == '237545') {
          doubleKit = 1;
        } else {
          doubleKit = 0;
        }


        //BingTracking.trackEvent("Payment", "Bing", "Atomic", item.orderPaid);
        //BingTracking.trackEvent('event', '', {'revenue_value': item.orderPaid, 'currency': 'GBP'});

      }

    });  

  }

  const completePurchase = (payload, event) => {
    //let props = props; 
    setIsLoading(true);
    dispatch(updatePaymentIntent(payload))
    .then((response) => {

      if (response.error) {
        props.cardFormState('failure')
        setIsLoading(false);
        if (event) {
          event.complete('fail');
        }
      } else {

        runGA(response.response.ordersUpdated);
        props.cardFormState('success')
        setIsLoading(false);
        if (event) {
          event.complete('success');
        }
        setIsAuthenticated(true);

        localStorage.removeItem('FromPartnerPortal')
        localStorage.removeItem('customerId')
        localStorage.removeItem('testId')
        localStorage.removeItem('orderCookieId')
        localStorage.removeItem('voucher')
        localStorage.removeItem('voucherStatus')
      }
      
      
    })  

  }


  const handlePaymentElementChange = (e) => {
    if (e.complete) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    } 
    props.setPaymentType(e.value.type)
  }
 
  return (
    <div className="checkoutForm stripe"> 
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-12"> 
            <PaymentElement id="payment-element" onChange={(e) => handlePaymentElementChange(e)}/> 
          </div> 
          {isLoading ? ( 
            <div className="col-12">
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : ( 
            <div className="col-12 text-center">
              <Button id="stripeSubmit mt-3" disabled={isDisabled}>Pay £{props.totalAmount}</Button>
            </div>
          )}
          {errorMessage ? ( 
            <div className="col-12">
              <div className="error text-center pt-3" role="alert">
                <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
              </div>
            </div>
          ) : ( 
            null
          )}
        </div>
      </form>
    </div>
 
  )
}