import React, {useState, useEffect} from 'react';
import {PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';
import calculateBasket from '../../../helpers/calculateBasket';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { updatePaymentIntent } from '../../../actions/basket-actions'
import moment from 'moment'
import _ from 'lodash';
import find from 'lodash/find'
import { useDispatch } from 'react-redux';
export default function QuickPay(props) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [customerEmailAddress, setCustomerEmailAddress] = useState('');
  const [customerAddress1, setCustomerAddress1] = useState('');
  const [customerAddress2, setCustomerAddress2] = useState('');
  const [customerCity, setCustomerCity] = useState('');
  const [customerPostcode, setCustomerPostcode] = useState('');
  const [customerCountry, setCustomerCountry] = useState('UK');
  const [userId, setUserId] = useState('');
  const voucher = JSON.parse(localStorage.getItem('voucher'));
  const isMounted = useIsMounted();

  
  useEffect(() => {   
    let deliveryPrice = parseInt((props.deliveryOption * 100));
    if (stripe && isMounted.current) { 
      let totalAmount =  parseFloat((props.totalAmount * 100).toFixed(2));
        const paymentRequestStripe = stripe.paymentRequest({
          country: 'GB',
          currency: 'gbp',
          total: {
            label: 'Forth',
            amount: totalAmount,
          },
          requestPayerEmail: true,
          requestPayerName: true,
          requestShipping: true,
          shippingOptions: 
            [
              {
                id: 'shipping',
                label: 'Delivery',
                detail: 'Selected delivery option',
                amount: deliveryPrice,
              }
            ],
          shippingAddress: true
        });

        paymentRequestStripe.on('paymentmethod', function(event) { 
 
          let payerEmail = localStorage.getItem("payerEmail");
          let orderCookieId = localStorage.getItem("orderCookieId")
          let payerName = localStorage.getItem("payerName");
          let payerAddressLine1 = event.shippingAddress.addressLine[0];
          let payerAddressLine2 = event.shippingAddress.addressLine[1];
          let payerCity = event.shippingAddress.city;
          let payerPostcode = event.shippingAddress.postalCode;
          let payload = {
            customerEmailAddress: payerEmail,
            customerPassword:
              Math.random()
                .toString(36)
                .substring(2, 15) +
              Math.random()
                .toString(36)
                .substring(2, 15),
            orderCookieId: orderCookieId,
            guestCheckout: true,
          };

  
          props.loadCreateAccount(payload).then((response) => {
            if (response.error) { 
              props.removeAllValues()
              props.cardFormState('failure');
            } else {  
              let userId = response.response.newCustomerId; 
              setUserId(userId)
              localStorage.setItem("payerUserId", userId);
              let updatePayload = {
                customerFirstName: payerName.split(" ")[0],
                customerLastName: payerName.split(" ")[1],
                customerEmailAddress: payerEmail,
                customerHomeTelephone: '',
                customerDOBD: '01',
                customerDOBM: '01',
                customerDOBY: '1970',
                customerSex: 0,
                customerEthnicity: 0,
                customerMenopausal: 0,
                customerWeight: 0,
                customerWeightST: 0,
                customerWeightIB: 0,
                customerWeightType: 1,
                customerHeight: 0,
                customerHeightFT: 0,
                customerHeightIN: 0,
                customerHeightType: 1,
                customerAddress1: payerAddressLine1, 
                customerAddress2: payerAddressLine2,
                customerAddress3: '',
                customerAddress4: '',
                customerCity: payerCity,
                customerCountry: "UK",
                customerPostcode: payerPostcode,
                customerOptIn: 0,
                customerMainGoal: 0,
                customerExercise: 0,
                customerPrimarySport: 0,
                customerOftenExercise: 0,
                customerTraining: "",
                customerHealthyDiet: 0,
                customerDiet: 0,
                customerAlcoholUnitsId: 0,
                customerSupliments: 0,
                customerSuplimentsDetails: 0,
                customerSuffer: 0,
                customerSufferDetails: 0,
                customerRegularPeriods: 0,
                customerContraception: 0,
                guestCustomerId: userId,
                guestCustomer: true,
                doNotReview: 0,
                overideAndAllowFHM: 0,
              }; 
 
              props.updateUserProfile(updatePayload).then((response) => {
                if (response.error) {
                  props.removeAllValues()
                  props.cardFormState('failure');
                }  else {
                  handlePayment(event); 
                }
              });  
                
            }
          }); 
          
        });

        paymentRequestStripe.on('shippingaddresschange', function(event) {

          if (!event.shippingAddress) {
            event.updateWith({status: 'invalid_shipping_address'});
          } else { 
            event.updateWith({ status: 'success' });
          }
        });
 
        paymentRequestStripe.on('shippingoptionchange', function(event) {
 
          if (!event.shippingOption) {
            event.updateWith({status: 'invalid_shipping_address'});
          } else { 
            event.updateWith({ status: 'success' });
          }
        });  

        paymentRequestStripe.on('token', ({complete, token, ...data}) => {
          console.log(data);
          console.log(token);
          localStorage.setItem("payerEmail", data.payerEmail);
          localStorage.setItem("payerName", data.shippingAddress.recipient);
          localStorage.setItem("payerAddressLine1", token.card.address_line1);
          localStorage.setItem("payerAddressLine2", token.card.address_line2);
          localStorage.setItem("payerCity", token.card.address_city);
          localStorage.setItem("payerPostcode", token.card.address_zip);
          setCustomerEmailAddress(data.payerEmail)
          setCustomerName(data.payerName)
          setCustomerAddress1(token.card.address_line1)
          setCustomerAddress2(token.card.address_line2)
          setCustomerCity(token.card.address_city)
          setCustomerPostcode(token.card.address_zip) 
        });

        paymentRequestStripe.on('cancel', function() { 
          //props.setReloadQuickPay()
          //props.removeAllValues()
          props.cardFormState('failure');
        });
        
        paymentRequestStripe.canMakePayment().then((result) => {
          if (result && !result.applePay && !result.googlePay && !result.link) { 
            try {
              setCanMakePayment(false); 
            } catch (e) {
              console.log(e); 
            } 
          } else {  
            try {
              setCanMakePayment(!!result); 
            } catch (e) {
              console.log(e); 
            }  
          }
        }, (err) => {
          console.log(err);
        }); 
        setCanMakePayment(false)
        setPaymentRequest(paymentRequestStripe);
   
 
    }
  }, [stripe]);

  const handleClick = () => {
    props.setGuestCheckout(true);
    props.setGuestCheckoutValue(true);

    let orderCookieId = localStorage.getItem("orderCookieId")
    props.updateOrderWithDeliveryOption(orderCookieId, props.deliveryOption, props.phlebotomyOption).then( response => {
      let stateVoucher = localStorage.getItem("voucher");
      let voucherCode = "";
      if (!voucher && stateVoucher) { 
        setVoucher(JSON.parse(stateVoucher));
        voucherCode = JSON.parse(stateVoucher).voucherCode;
      } 
      if (voucher) { 
        voucherCode = voucher.voucherCode;
      } 
      let date = moment().format("YmdHisu");
      let cookie = date + Math.floor(9999999 + Math.random() * 9999999) + Math.floor(9999999 + Math.random() * 9999999) + Math.floor(9999999 + Math.random() * 9999999);
      let user = props.user;
      let basket = props.basket;
      let subscription = props.subscriptionOption;
      let tests = props.basketTestItems;
      let items = [];
      let phlebotomy = props.phlebotomyOption; 
      let partnerPortalId = localStorage.getItem("partnerPortalId");
 
      Object.keys(basket).map(function(key) {
        find(tests, (test) => {
          if (parseInt(test.testId) === parseInt(basket[key])) {
            items.push(test);
          } else {
            return false;
          }
        });
      });
      

  
      if (!localStorage.getItem("orderCookieId")) { 
        if(window.parent) {
          window.parent.postMessage({orderCookieId: cookie}, '*');
        } 
        localStorage.setItem("orderCookieId", cookie);

        var cname="orderCookieId";
        var cvalue = cookie;
        var days = 999;
        var dt = new Date();
        dt.setTime(dt.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+dt.toGMTString();
        document.cookie = cname+"="+cvalue+expires+'; path=/; domain=forthwithlife.co.uk';
      } else {
        cookie = localStorage.getItem("orderCookieId");
      }

      let update = 1;
      let count = 0;
 
      props.loadClearBasket(cookie).then(function(response) {
        Object.keys(items).map(function(key) {
          count++;

          if (items[key].subscriptionAvailable == 0) {
            subscription = 0;
          }

          if (count == 1) {
            update = 1;
          } else {
            update = 0;
          }
 
      
          props.loadAddToBasket(items[key].testId,cookie,user.iD,subscription,"",0,voucherCode,phlebotomy,update,props.deliveryOption,partnerPortalId)
          .then(function(response) {
            if (!response.error) {
              
              props.renderQuickpayData();

            }
          });
        });
      });
    }) 


    

  }

  const handlePayment = async (event) => {
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    
    let voucherId = 0;
    if (props.voucher) {
      voucherId = props.voucher.iD;
    }

    let partnerCode = ''
    let partnerPortalId = localStorage.getItem('partnerPortalId');
    if ((global.config.websiteId == 3 || partnerPortalId != 0) && Object.keys(partner).length > 0) { 
      partnerCode = props.partner[0].requestCode;  
    }  

    let payerName = localStorage.getItem("payerName");
    let payerEmail = localStorage.getItem("payerEmail");

    let payerStripeCustomerId  = localStorage.getItem("payerStripeCustomerId");
    let payerPaymentIntent  = localStorage.getItem("payerPaymentIntent");
    let payerClientSecret  = localStorage.getItem("payerClientSecret");

    let payerOrderId = localStorage.getItem("payerOrderId");
    let payerOrderReference = localStorage.getItem("payerOrderReference");
    let payerTotalAmount = localStorage.getItem("payerTotalAmount");
    let payerCredit = localStorage.getItem("payerCredit");
    let payerDiscountedCredit = localStorage.getItem("payerDiscountedCredit");
 
    let payload = { 
      userId: localStorage.getItem("payerUserId"),
      userIp: '',
      websiteId: global.config.websiteId,
      amount: props.amount,
      id: payerPaymentIntent,
      orderReference: payerOrderReference,
      orderId: payerOrderId,
      stripeCustomerId: payerStripeCustomerId, //CHECK PARENT COMPONENT FOR THIS
      userEmail: payerEmail,
      totalAmount: props.totalAmount,
      totalSaving: props.cart.totalSaving,
      subscriptionOrder: false,
      activationKit: true,
      partnerId: partnerPortalId,
      partnerCode: partnerCode,
      overidePayPalProcess: '',
      voucherId: voucherId,
      f: false,
      freeOrderEncryptedUrl: '',
      stripeToken: payerPaymentIntent,
      orderCookieId: localStorage.getItem("orderCookieId"),
      doNotReview: 0,
      credit: payerCredit,
      discountedCredit: payerDiscountedCredit,
      receiptEmailAddress: payerEmail,
      grantPermissionToPartner: false,
      cart: props.cart,
      partner: props.partner,
      phlebotomyOption: props.phlebotomyOption,
      basketTestItems: props.basketTestItems,
      voucher: props.voucher,
      customerEmailAddress: payerEmail,
      customerFirstName: payerName.split(" ")[0],
      customerLastName : payerName.split(" ")[1],
      checkoutFromPartner: 0,
      clientEmailsCheck: 0,
      clientReciept: '',
      quickPay: true,
      clientReferenceId: window?.Rewardful?.referral ?? ''
    }  

 
 
    stripe.confirmCardPayment( 
      payerClientSecret, {payment_method: event.paymentMethod.id}).then(function(result) {
        if (result.error) {
          props.removeAllValues()
          props.cardFormState('failure')
        } else {  
          completePurchase(payload, event); 
        }
      });     
      
  }


  const completePurchase = (payload, event) => {
    //setIsLoading(true);
    dispatch(updatePaymentIntent(payload))
    .then((response) => {

      if (response.error) {
        props.removeAllValues()
        props.cardFormState('failure') 
        if (event) {
          event.complete('fail');
        }
      } else {
        props.cardFormState('success')
        props.trackQuickPay();
        //setIsLoading(false);
        if (event) {
          event.complete('success');
        } 
        
        localStorage.removeItem('payerEmail')
        localStorage.removeItem('payerName')
        localStorage.removeItem('payerAddressLine1')
        localStorage.removeItem('payerAddressLine2')
        localStorage.removeItem('payerCity')
        localStorage.removeItem('payerPostcode')
        localStorage.removeItem('payerUserId')
        localStorage.removeItem('payerStripeCustomerId')
        localStorage.removeItem('payerPaymentIntent')
        localStorage.removeItem('payerClientSecret')
        localStorage.removeItem("payerOrderId");
        localStorage.removeItem("payerOrderReference");
        localStorage.removeItem("payerSubscriptionOrder");
        localStorage.removeItem("payerTotalAmount");
        localStorage.removeItem("payerCredit");
        localStorage.removeItem("payerDiscountedCredit");
      }
      
      
    }) 
    

  }

  if (canMakePayment && paymentRequest && isMounted.current) {
    return <PaymentRequestButtonElement onClick={() => handleClick()} options={{paymentRequest}} />
  }

  // Use a traditional checkout form.
  return '';
}